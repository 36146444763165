import MessageMap from "../i18n";

const ptBR: MessageMap = {
  RESTRICTION_ATTRIBUTES: {
    TYPE: "Tipo",
    KEY: "Chave",
    CASHBACK: "Cashback",
    ONBOARDING: "Onboarding",
    LAST_EDIT: "Última edição",
    EDIT_BY: "Editador por",
    EDIT: "Editar",
    TITLE: "Lista de Restrição",
    ANTIFRAUD: "Antifraude",
    COUNTRY_OF_RESTRICTION: "País da Restrição",
    COUNTRY: "País",
    NEW_RESTRICTION: "Nova Restrição",
    PLACEHOLDER_SEARCH: "Buscar chave da restrição",
    ZE_COMPENSA: "Zé Compensa",
    CLUB_TADA: "Club TaDa",
    NEW_KEY_RESTRICTION: "Nova Chave de Restrição",
    RESTRICTION_DETAILS: "Detalhes da restrição",
    KEY_TYPE: "Tipo de chave",
    KEY_TYPE_PLACEHOLDER: "Selecione o tipo de restrição",
    KEY_TO_RESTRICT: "Chave para restringir",
    KEY_TO_RESTRICT_PLACEHOLDER: `Ex: "restrict.user@gmail.com”`,
    KIND_OF_RESTRICTION: "Tipo de restrição",
    RESTRICTION_REASON: "Motivo da restrição",
    RESTRICTION_REASON_PLACEHOLDER: "Selecione o motivo da restrição",
    CONFIRM_THE_UPDATE_FOR_RESTRICTION:
      "Confirme a atualização para restrições",
    LOCKED: "Bloqueado",
    UNLOCKED: "Desbloqueado",
    EDIT_RESTRICTION_KEY: "Editar Chave de Restrição",
    EMAILS: "E-mails",
    KEY_INPUT_PLACEHOLDER: "E.g. newrestriction2",
  },
  EDIT_RESTRICTION: {
    RESTRICTION_TABS_TITLES: {
      RESTRICTION_DETAILS: "Detalhes da restrição",
      MANAGE_RESTRICTION_EMAILS: "Gerenciar e-mails restringidos",
      LAST_ALTERATIONS: "Últimas alterações",
    },
    ACTIVE_ONBOARDING_RESTRICTION: "Ativo",
    DETAILS_OF_RESTRICTION: "Detalhes da restrição",
    TYPE_OF_KEY: "Tipo de chave",
    KEY_OF_RESTRICTION: "Chave de restrição",
    COUNTRY_OF_RESTRICTION: "País da restrição",
    EMAILS_OF_RESTRICTION: "Emails of restriction",
    TYPE_OF_RESTRICTION: "Tipo de restrição",
    REASON_OF_RESTRICTION: "Motivo da restrição",
    UPDATE_RESTRICTION: "Atualizar restrição",
  },
  RESTRICTION_HISTORY: {
    TITLE: "Histórico de restrição",
    ONBOARDING_LOCKED: "Onboarding - Bloqueado",
    ONBOARDING_UNLOCKED: "Onboarding - Desbloqueado",
    RESTRICTION_UPDATED: "Restrição modificada",
    RESTRICTION_CREATED: "Restrição criada",
  },
  EMAILS_RESTRICTION: {
    TITLE: "Emails restringidos",
    DESCRIPTION:
      "Esses e-mails seguem os detalhes da restrição. Altere o status para <bold>Sim</bold> apenas se desejar que esse e-mail específico ignore as restrições.",
    LABEL_INPUT_ADD_EMAIL: "Adicionar novo e-mail",
    PLACEHOLDER_INPUT_ADD_EMAIL: "E.g. ”addnew@mail.com”",
    BUTTON_ADD_EMAIL: "Adicionar novo e-mail",
    USER_COLUMN: "Email",
    ADDED_IN_COLUMN: "Adicionado em",
    UPDATED_IN_COLUMN: "Atualizado em",
    IGNORE_RESTRICTION_COLUMN: "Ignorar restrição",
    YES: "Sim",
    NO: "Não",
  },
  LAST_ALTERATION_RESTRICTION: {
    TITLE: "Últimas alterações",
    DESCRIPTION:
      "Uma lista com todas as alterações referentes a esta restrição.",
    USER_COLUMN: "Email",
    UPDATE_IN_COLUMN: "Atualizado em",
    MODIFICATION_COLUMN: "Modificação",
    EMPTY: {
      TITLE: "Opa...",
      MESSAGE: "Não há informações para mostrar.",
    },
  },
  RESTRICTION_TABLE: {
    HEADERS: {
      KEY: "Chave",
      TYPE: "Tipo",
      ONBOARDING_RESTRICTION: "Restrição no Onboarding",
      LAST_UPDATE: "Última Alteração",
      EDIT: "Editar",
    },
    DOCUMENT_TYPE: {
      HN: "RTN",
      BR: "CPF",
      SV: "NIT ou DUI",
      PA: "RUC",
      BO: "NIT",
      PY: "RUC",
      PE: "DNI ou RUC",
      EC: "RUC",
      DO: "Cédula",
      CO: "NIT",
      MX: "RFC",
      ZA: "TIN",
      AR: "DNI",
    },
    TYPE: {
      DOCUMENT: "CPF",
      EMAIL: "E-mail",
      PHONE_NUMBER: "Telefone",
      DEVICE_ID: "Dispositivo",
      DEVICE_AND_EMAIL: "Dispositivo e e-mail",
    },
    LOADING: {
      TITLE: "Carregando",
      MESSAGE: "Estamos preparando a lista de restrições para você.",
    },
    EMPTY: {
      TITLE: "Opa...",
      MESSAGE: "Não recebemos nenhum dado deste país.",
    },
    FILTERED: {
      TITLE: "Ainda sem restrição",
      MESSAGE: "A restrição será exibida aqui.",
    },
  },
  BUTTONS: {
    EDIT: "Editar",
  },
  TEST: {
    TRANSLATE: "Translate",
    MISSING: "Only English option available",
  },
  SIDEMENU: {
    TITLE: "Home",
  },
  ACTIONS: {
    GO_BACK: "Voltar",
    BACK: "Voltar",
    CANCEL: "Cancelar",
    CONFIRM: "Confirm",
    SAVE: "Salvar",
    SEARCH: "Busca",
    APPLY: "Aplicar",
    COPY: "Copiar",
    EDIT: "Editar",
    SELECT: {
      DEFAULT: "Selecione",
      ALL: "Selecionar todos",
      ONE_OR_MORE: "Selecione um ou mais",
    },
    REMOVE: "Remover",
    YES: "Sim",
    NO: "Não",
    SHOW_DETAILS: "Mostrar Detalhes",
    CREATE: "Criar",
    CONTINUE: "Continuar",
    PUBLISH: "Publicar",
    TRY_AGAIN: "Tente novamente",
    CONFIRM_RESTRICTION: "Confirmar restrição",
  },
  COUNTRIES: {
    HN: "Honduras",
    SV: "El Salvador",
    PA: "Panamá",
    BO: "Bolívia",
    DO: "Rública Dominicana",
    PY: "Paraguai",
    PE: "Peru",
    EC: "Equador",
    ZA: "África do Sul",
    CO: "Colombia",
    MX: "México",
    AR: "Argentina",
    BR: "Brasil",
  },
  UNAUTHORIZED_PAGE: {
    TITLE: "Oops, access denied!",
    DESCRIPTION: "It seems you don't have permission to access this page.",
    ACTION: "Go back to the homepage",
  },
  PAGINATION: {
    QUANTITY_INDICATOR: "de",
    PAGE_SIZE_OPTIONS: "Itens por página: {options}",
  },
  ERROR: {
    400: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "An unexpected error ocurred. Please try again.",
    },
    401: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "Contact our support team to review your account settings.",
    },
    403: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "Contact our support team to review your account settings.",
    },
    404: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "We couldn't perform this action. Try again.",
    },
    500: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "An unexpected error ocurred. Please try again.",
    },
    502: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "An unexpected error ocurred. Please try again.",
    },
    503: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "An unexpected error ocurred. Please try again.",
    },
  },
  FIELDS: {
    OPTIONAL: "Opcional",
    REQUIRED: {
      GENERIC: "Campo obrigatório",
    },
    FORMAT: {
      GENERIC: "Formato inválido",
      INVALID: "Inválido",
    },
    VALIDATION: {
      DUPLICATED: "Esta chave já está restrita.",
    },
  },
  TOASTS: {
    RESTRICTION_CREATED_SUCCESSFULLY: "Restrição criada com sucesso!",
    RESTRICTION_UPDATED_SUCCESSFULLY: "Restrição editada com sucesso!",
  },
  VERSION: {
    VERSION_APP: "Versão",
    SERVER: "Servidor",
    RIGHTS_RESERVED: "Anheuser-Busch Inbev S.A. Todos os direitos reservados",
  },
};

export default ptBR;
