import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@hexa-ui/theme";
import { createFeatureToggleServiceV2 } from "admin-portal-shared-services";
import { BrowserRouter } from "react-router-dom";
import { getApolloClient } from "./Api/graphql/graphql";
import { EnvConfig, EnvProvider } from "./components";
import AppBar from "./components/AppBar/AppBar";
import FooterVersion from "./components/FooterVersion/FooterVersion";
import { FEATURE_TOGGLE_V2_PROJECT_NAME } from "./config/featureToggles";
import { IntlProvider } from "./i18n";
import { getInitialCountry } from "./utils/AppUtils";

export default function App(props: Readonly<EnvConfig>) {
  const { OPTIMIZELY_KEY } = props;
  createFeatureToggleServiceV2(
    FEATURE_TOGGLE_V2_PROJECT_NAME,
    OPTIMIZELY_KEY || ""
  );

  const client = getApolloClient(getInitialCountry(props));

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme="tadaPrimary">
        <EnvProvider env={props}>
          <IntlProvider>
            <BrowserRouter>
              <AppBar />
              <FooterVersion />
            </BrowserRouter>
          </IntlProvider>
        </EnvProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}
