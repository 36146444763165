import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import {
  useAuthenticationService,
  useEnvService,
} from 'admin-portal-shared-services'
import fetch from 'cross-fetch'

import { randomUUIDV4 } from '@/utils/string/stringUtils'

import {
  ORCHESTRATOR_LOWER_ENV_URL,
  ORCHESTRATOR_PROD_URL,
} from '../../../config/api'

interface RefreshTokenResponse {
  access_token: string
  token_type: string
  expires_in: number
  refresh_token: string
  id_token: string
}

let refreshTokenPromise: Promise<string | null> | null = null

const ensureBearer = (token: string | null): string => {
  if (!token) return ''
  return token.startsWith('Bearer ') ? token : `Bearer ${token}`
}

const removeBearer = (token: string | null): string => {
  if (!token) return ''
  return token.startsWith('Bearer ') ? token.replace('Bearer ', '') : token
}

/**
 * Get the final Orchestrator GraphQL URL
 * @param {string} country
 * @returns Orchestrator GraphQL URL
 */
export const orchestratorGraphqlUrl = (country: string): string => {
  const environment = useEnvService().getEnv().toLowerCase()

  let orchestratorUrl = ORCHESTRATOR_LOWER_ENV_URL

  switch (environment) {
    case 'qa':
      orchestratorUrl = orchestratorUrl.replace('ENV', 'sit')
      break
    case 'uat':
      orchestratorUrl = orchestratorUrl.replace('ENV', environment)
      break
    default:
      orchestratorUrl = ORCHESTRATOR_PROD_URL
      break
  }

  orchestratorUrl = orchestratorUrl.replace('COUNTRY', country)
  return orchestratorUrl
}

/**
 * Generate httpLink for Apollo Client
 * @param country
 * @returns httpLink
 */
export const orchestratorHttpLink = (country: string): HttpLink => {
  const httpLink = new HttpLink({
    uri: orchestratorGraphqlUrl(country),
    fetch,
  })
  return httpLink
}

export const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('authHeader')

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: ensureBearer(token),
      requestTraceId: randomUUIDV4(),
    },
  }))

  return forward(operation)
})

const synchronizedTokenRefresh = async (): Promise<string | null> => {
  const authentication = useAuthenticationService()

  if (!refreshTokenPromise) {
    refreshTokenPromise = (async () => {
      try {
        const currentRefreshToken = localStorage.getItem('refresh_token')

        if (!currentRefreshToken) {
          console.warn(
            '[Apollo] Auth: No current refresh token available for refresh'
          )
          return null
        }

        const refreshResponse = (await Promise.resolve(
          authentication.refreshToken(removeBearer(currentRefreshToken))
        )) as unknown as { data: RefreshTokenResponse }

        const refreshResult = refreshResponse.data
        if (!refreshResult?.access_token) {
          console.warn('[Apollo] Auth: No access_token in refresh result')
          return null
        }

        localStorage.setItem(
          'authHeader',
          `Bearer ${refreshResult.access_token}`
        )
        localStorage.setItem('refresh_token', refreshResult.refresh_token)
        console.log(
          '[Apollo] Auth: All tokens refreshed and saved successfully'
        )

        await new Promise((resolve) => setTimeout(resolve, 500))

        return `Bearer ${refreshResult.access_token}`
      } catch (error) {
        console.error('[Apollo] Auth: Token refresh failed', error)
        return null
      } finally {
        refreshTokenPromise = null
      }
    })()
  }

  return refreshTokenPromise
}

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    console.log('[Apollo] ErrorLink: Checking errors...', {
      hasNetworkError: !!networkError,
      hasGraphQLErrors: !!graphQLErrors?.length,
      operationName: operation.operationName,
    })

    const isAuthError =
      (networkError &&
        'statusCode' in networkError &&
        networkError.statusCode === 401) ||
      graphQLErrors?.some(
        (err) =>
          err.message.includes('Jwt is expired') ||
          err.message.includes('jwt expired') ||
          err.extensions?.code === 'UNAUTHENTICATED' ||
          err.message.includes('unauthorized')
      )

    if (isAuthError) {
      console.warn('[Apollo] Auth: Token expired, attempting refresh')

      return new Observable((observer) => {
        synchronizedTokenRefresh()
          .then((newToken) => {
            if (!newToken) {
              console.error(
                '[Apollo] Auth: Could not refresh token, redirecting to login'
              )
              observer.complete()
              return
            }

            console.log(
              '[Apollo] Auth: Token refreshed successfully, updating operation context'
            )

            operation.setContext(({ headers = {} }) => ({
              headers: {
                ...headers,
                Authorization: newToken,
              },
            }))

            console.log(
              '[Apollo] Auth: Retrying operation with new token:',
              operation.operationName
            )
            forward(operation).subscribe({
              next: (result) => {
                console.log(
                  '[Apollo] Auth: Operation succeeded after token refresh:',
                  operation.operationName
                )
                observer.next(result)
              },
              error: (retryError) => {
                if (retryError?.networkError?.statusCode === 401) {
                  console.error(
                    '[Apollo] Auth: Still getting 401 after token refresh'
                  )
                  observer.complete()
                  return
                }
                console.error(
                  '[Apollo] Auth: Operation failed after token refresh:',
                  operation.operationName,
                  retryError
                )
                observer.error(retryError)
              },
              complete: () => {
                console.log(
                  '[Apollo] Auth: Operation completed after token refresh:',
                  operation.operationName
                )
                observer.complete()
              },
            })
          })
          .catch((error) => {
            console.error('[Apollo] Auth: Token refresh failed', error)
            observer.complete()
          })
      })
    }

    if (networkError) {
      console.error('[Apollo] Network error:', {
        name: networkError.name,
        message: networkError.message,
        statusCode:
          'statusCode' in networkError ? networkError.statusCode : undefined,
        stack: networkError.stack,
        operationName: operation.operationName,
      })
    }

    if (graphQLErrors?.length) {
      graphQLErrors.forEach((error, index) => {
        console.error(`[Apollo] GraphQL error ${index + 1}:`, {
          message: error.message,
          path: error.path,
          code: error.extensions?.code,
          locations: error.locations,
          operationName: operation.operationName,
        })
      })
    }
  }
)

/**
 * Generate new client on every request
 * @param { string } country
 * @returns Apollo client
 */
export const getApolloClient = (
  country: string
): ApolloClient<NormalizedCacheObject> => {
  const client = new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      authMiddleware,
      orchestratorHttpLink(country),
    ]),
    cache: new InMemoryCache({ addTypename: false }),
  })
  return client
}
